import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import shapeBelow from "../Images/Shape.png"
import Global from "../Images/Global.png"
import Master from "../Images/Mastercard.png"
import Visa from "../Images/visa-logo.png"
import Paypal from "../Images/PayPal.png"
import American from "../Images/image 4.png"
import card from "../Images/cardIcon.png"
import DateIcon from "../Images/Date.png"
import lock from "../Images/lock.png"
import { setpebtofalse } from '../Redux/reducers/peb/pebSlice'
import PEB from "../Images/pebImg.png"
import Footer from '../Footer/Footer'
import { useEffect } from 'react';
import {
    CardElement, PaymentElement, useElements, useStripe, CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";
import { autoBatchEnhancer } from '@reduxjs/toolkit'
import { useSelector, useDispatch } from "react-redux";
import { setBannerTrue } from "../Redux/reducers/banner/bannerSlice";
import {
    setLogin,
    setCustomerFalse,
} from "../Redux/reducers/customer/customerSlice";
import { RedirectTo } from "../Redux/reducers/redirect/redirectSlice";
import { Mixpanel } from "../Mixpanel";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom'

const inputStyle = {
  iconColor: "#c4f0ff",
  backgroundColor: "#F9F9F9",
  color: "#000",
  width: "auto",
  position: "absolute",
  fontWeight: "500",
  fontFamily: "Lato sans-serif",
  fontSize: "16px",
  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: "#000",
  },
  "::placeholder": {
    color: "#9B9B9B",
  },
};

const PaymentForm = () => {
    const [dropdownOpen, setDropdownopen] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    let dispatch = useDispatch();
    let navigate = useNavigate()

    const toggleLogin = () => {
        Mixpanel.track("Press login button web");
        // dispatch({ type: 'setLogin' })
        dispatch(setLogin());
        dispatch(RedirectTo({ url: window.location.pathname }))
        // dispatch({ type: 'setBannerToTrue' })
        dispatch(setBannerTrue());
        dispatch(setpebtofalse());

        // setLogin(!login);
    };

    useEffect(() => {
        dispatch(setpebtofalse());
    }, [])
   


    // main function
    const createSubscription = async () => {
        let user_id;
        if (localStorage.getItem("cp_web_token")) {
            user_id = jwt_decode(localStorage.getItem("cp_web_token")).id

        } else if (localStorage.getItem("customer_signup_token")) {
            user_id = jwt_decode(localStorage.getItem("customer_signup_token")).id

        } else if (localStorage.getItem("signup_token")) {
            user_id = jwt_decode(localStorage.getItem("signup_token")).id
        }

        if (localStorage.getItem("cp_web_token") || localStorage.getItem("customer_signup_token") || localStorage.getItem("signup_token")) {
            try {
                setIsLoading(true);
                setError(false);
                let token = localStorage.getItem("cp_web_token")
                // create a payment method
                const paymentMethod = await stripe?.createPaymentMethod({
                    type: "card",
                    card: elements?.getElement(CardNumberElement),
                    billing_details: {
                        name: 'PRIVATE EQUITY BUNDLE',
                    },
                });

                // call the backend to create subscription
                axios.request({
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: process.env.REACT_APP_SERVER_PATH + '/subscriptions',
                    data: JSON.stringify({
                        "user": Number(user_id),
                        "stripePaymentMethodID": paymentMethod?.paymentMethod?.id,
                        "stripeProductID": process.env.REACT_APP_stripeProductID,
                        "stripePriceID": process.env.REACT_APP_stripePriceID,
                        "description": "Subscription ................"
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }).then(async (response) => {
                    console.log(JSON.stringify(response.data));
                    const confirmPayment = await stripe?.confirmCardPayment(
                        response?.data?.message?.clientSecret
                    );

                    if (confirmPayment?.error) {
                        alert('Failed to charge your card. Please try again');
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        window.location = "/dashboard/home";
                        localStorage.setItem("PEB-Alert", "show")
                    }
                }).catch(function (error) {
                    if (error.response) {
                        setError(true);
                        setErrorMessage(error?.response?.data?.error);
                        console.log("🚀 ~ file: paymentForm.js:133 ~ createSubscription ~ error?.response?.error:", error?.response)
                        setIsLoading(false);
                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser 
                        // and an instance of http.ClientRequest in node.js
                        console.log(error.request);
                        setError(true);
                        setErrorMessage(error.request);
                        setIsLoading(false);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log('Error', error.message);
                        setError(true);
                        setErrorMessage(error.message);
                        setIsLoading(false);
                    }

                });


            } catch (error) {
                console.log(error);
            }
        } else {
            toggleLogin();
            return null;
        }

    };
    const handleToggleDropdown = () => {
        setDropdownopen(!dropdownOpen);
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
      <>
        <div className="col-span-3">
          <h1
            className="text-2xl px-8"
            style={{ fontFamily: 'Lato sans-serif' }}
          >
            Checkout
          </h1>
          {/* <p className='text-sm mt-3 font-bold'>Billing Address</p> */}
          {/* <p className='text-xs mt-5'>Country</p> */}
          <div className="relative">
            {/* <a
                        className="flex items-center h-10 pl-3 pr-2 mt-2 rounded-lg border lg:w-1/2 sm:w-3/4 cursor-pointer border-[#A7A7A7] focus:outline-none"
                        onClick={handleToggleDropdown}
                    >
                        <img src={Global} className="h-4 w-4 flex" alt="Shape" />
                        <span className="ml-4 mr-4 text-xs text-black leading-none">United Kingdom</span>
                        <img src={shapeBelow} className="h-1 w-2 mt-0.5 flex" alt="Shape" />
                    </a> */}
            {dropdownOpen && (
              <div className="absolute z-20 bg-white flex flex-col lg:w-1/2 sm:w-3/4  rounded-lg mt-2 border border-[#A7A7A7] shadow-lg">
                <div className="leading-loose">
                  <a className="flex items-center h-12 px-3 text-sm cursor-pointer hover:rounded-lg hover:bg-[#262626]">
                    <img src={Global} className="h-4 w-4 flex" alt="Shape" />
                    <span className="ml-4 mr-4 text-xs text-black leading-none">
                      United Kingdom
                    </span>
                  </a>
                  <a className="flex items-center h-12 px-3 text-sm cursor-pointer hover:rounded-lg hover:bg-[#262626]">
                    <img src={Global} className="h-4 w-4 flex" alt="Shape" />
                    <span className="ml-4 mr-4 text-xs text-black leading-none">
                      United Kingdom
                    </span>
                  </a>
                </div>
              </div>
            )}
            {/* <p className='text-sm mt-8 font-bold'>Select Card</p>
                                <div className='flex flex-row mt-2 gap-3'>
                                    <div className='px-3 py-2.5 w-1002 h-12 rounded-lg border border-gray-400 cursor-pointer bg-white hover:bg-peach hover:border-goldenColor'>
                                        <img src={Master} className='' /></div>
                                    <div className='px-3 py-4 w-1002 h-12 rounded-lg border border-gray-400 cursor-pointer bg-white hover:bg-peach hover:border-goldenColor'>
                                        <img src={Visa} className='' /></div>
                                    <div className='px-3 py-1.5 w-1002 h-12 rounded-lg border border-gray-400 cursor-pointer bg-white hover:bg-peach hover:border-goldenColor'>
                                        <img src={Paypal} className='' /></div>
                                    <div className='px-3 py-3 w-1002 h-12 border rounded-lg border-gray-400 cursor-pointer bg-white hover:bg-peach hover:border-goldenColor'>
                                        <img src={American} className='' /></div>
                                </div> */}
            <p className="text-sm mt-8 px-8 font-bold">Enter Card Details</p>
            <form id="paymentF">
              <div className="relative lg:w-full sm:w-full mt-2 p-8">
                <div
                  name="cardNumber"
                  className="bg-grayButton w-full h-14 pt-5 -mt-1 rounded-lg text-black pl-4"
                  // placeholder="Card Number"
                  // type="text"
                >
                  <CardNumberElement
                    options={{
                      style: {
                        base: inputStyle,
                      },
                    }}
                    onFocus={() => {
                      // if (window?.location?.pathname?.includes('checkout')) {
                      //   dispatch(setpebtofalse())
                      // } else {
                      dispatch(setpebtofalse());
                      // }
                    }}
                  />
                </div>

                <div className="absolute right-4 top-5 p-8">
                  <img src={card} className="w-5 h-5" />
                </div>
              </div>
              <div className="flex flex-row mt-2 gap-4 w-full px-8">
                <div className="relative w-2/3  lg:w-2/3">
                  <div
                    name="cardNumber"
                    className="bg-grayButton w-full h-14 pt-5  rounded-lg text-black pl-4"
                  >
                    <CardExpiryElement
                      options={{
                        style: {
                          base: inputStyle,
                        },
                      }}
                      onFocus={() => {
                        // if (window?.location?.pathname?.includes('checkout')) {
                        //   dispatch(setpebtofalse())
                        // } else {
                        dispatch(setpebtofalse());
                        // }
                      }}
                    />
                  </div>
                  <div className="absolute right-4 top-5 ">
                    <img src={DateIcon} className="w-5 h-5" />
                  </div>
                </div>
                <div className="relative w-1/3 sm:w-1/2 lg:w-1/3 ">
                  <div
                    name="cardNumber"
                    className="bg-grayButton w-full h-14 pt-5  rounded-lg text-black pl-4"
                  >
                    <CardCvcElement
                      options={{
                        style: {
                          base: inputStyle,
                        },
                      }}
                    />
                  </div>
                  <div className="absolute right-3 top-4 ">
                    <img src={lock} className="w-6 h-6" />
                  </div>
                </div>
              </div>
            </form>
            {error && (
              <>
                <p className="text-sm px-8 py-3" style={{ color: "red" }}>
                  {errorMessage}
                </p>
              </>
            )}
            <div className="flex flex-row items-center justify-end mt-2 gap-4 w-full">
              <div className="relative w-44 lg:w-1/2 xl:w-1/3 px-8 sm:hidden md:block">
                <button
                  type="button"
                  onClick={createSubscription}
                  disabled={isLoading}
                  name="Subscribe"
                  className={`flex items-center mt-4 text-white  justify-center px-3 w-full mx-auto py-3 ${
                    !isLoading ? "bg-bgAppColor" : "bg-gray-300"
                  } shadow-xl ${
                    !isLoading ? "hover:bg-cyan" : "hover:bg-gray-300"
                  } text-md font-medium rounded-lg `}
                  style={{ fontFamily: "Lato Bold" }}
                >
                  {" "}
                  {isLoading ? (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 mr-3 text-black animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  ) : null}
                  Checkout
                </button>
              </div>
            </div>

            <div className="flex flex-row  mt-5 gap-3 px-8">
              <div className="px-3 py-2.5 w-12 h-10 rounded-lg border border-gray-400 cursor-pointer bg-white hover:bg-peach hover:border-goldenColor">
                <img src={Master} className="" />
              </div>
              <div className="px-3 py-4 w-12 h-10 rounded-lg border border-gray-400 cursor-pointer bg-white hover:bg-peach hover:border-goldenColor">
                <img src={Visa} className="" />
              </div>
              <div className="px-3 py-1.5 w-12 h-10 rounded-lg border border-gray-400 cursor-pointer bg-white hover:bg-peach hover:border-goldenColor">
                <img src={Paypal} className="" />
              </div>
              <div className="px-3 py-3 w-12 h-10 border rounded-lg border-gray-400 cursor-pointer bg-white hover:bg-peach hover:border-goldenColor">
                <img src={American} className="" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-seaBlue sm:mt-10 lg:mt-0 pb-10 col-span-4">
          <div className="relative mt-2 p-5 pr-3 pl-3">
            <div className="p-2 px-4 bg-white rounded-lg grid sm:grid-cols-2 md:grid-cols-12">
              <div className="col-span-4">
                <div className="flex flex-col">
                  <img
                    src={PEB}
                    className=" h-16 sm:w-1012 sm:h-1012 sm:flex sm:mx-auto md:mx-0"
                  />
                  <h1
                    className="text-3xl text-black sm:text-center md:text-left p-2 pt-4"
                    // style={{ fontFamily: "Cerebri Bold" }}
                    style={{ fontFamily: "Lato Bold" }}
                  >
                    £15
                  </h1>
                  <p className="text-grayColor md:text-left sm:text-center text-sm px-2">
                    £180 billed annually
                  </p>
                </div>
              </div>
              <div className="col-span-8">
                <h1
                  className="text-xl pt-2 pb-2 text-black"
                  style={{ fontFamily: "Lato Bold" }}
                >
                  PRIVATE EQUITY BUNDLE
                </h1>
                <ul className="list-disc text-grayColor text-sm mb-4 px-4">
                  <li>1:1 mentor session with PE professional (45 min) </li>
                  <li>Private Equity 101 Training Pack</li>
                  <li>50+ Private Equity interview questions</li>
                  <li>10+ Private Equity practice case studies</li>
                  <li>How to build an LBO Model (Excel)</li>
                  <li>Exclusive video interviews with PE professionals</li>
                  <li>10% off all mentor sessions</li>
                </ul>
              </div>
            </div>
            <p className="text-xs p-4 text-grayColor">
              Note: This is a pre-order purchase. We are constantly refreshing
              our content to make sure you are learning from the best material.
              Our updated Private Equity Bundle will be available and accessible
              in your CareerPaths dashboard on 1st January 2024.
            </p>
          </div>
          <h1 className="text-2xl sm:text-xl px-9">Summary</h1>
          <div className="flex flex-col px-9">
            <div className="flex justify-between">
              <p className="text-darkGrayColor">Subtotal</p>
              <p>£180.00</p>
            </div>
            <hr className="text-black mt-1" />
            <div className="flex justify-between mt-5">
              <p className="text-darkGrayColor">Total due</p>
              <p className="text-bgAppColor">£180.00</p>
            </div>
          </div>
          {/* <button className="flex items-center mt-4 text-white justify-center px-3 w-1/2 mx-auto py-3 bg-bgAppColor shadow-xl hover:bg-gray-300 text-sm font-medium rounded-lg">
                                Checkout Form
                            </button> */}
          <div className="relative w-auto px-8 sm:block md:hidden">
            <button
              type="button"
              onClick={createSubscription}
              disabled={isLoading}
              name="Subscribe"
              className={`flex items-center mt-4 text-white  justify-center px-3 w-full mx-auto py-3 ${
                !isLoading ? "bg-bgAppColor" : "bg-gray-300"
              } shadow-xl ${
                !isLoading ? "hover:bg-cyan" : "hover:bg-gray-300"
              } text-md font-medium rounded-lg `}
              style={{ fontFamily: "Lato Bold" }}
            >
              {" "}
              {isLoading ? (
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 mr-3 text-black animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              ) : null}
              Checkout
            </button>
          </div>
        </div>
      </>
    );
}

export default PaymentForm
